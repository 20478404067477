<template>
  <fragment>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <div
          id="cajaIntMine"
          class="d-block justify-center align-center"
        >
          <fragment v-if="!cotizadorFlight.destinosMultiples">
            <div class="d-flex justify-center align-center">
              <h3>
                {{
                  destinosCotizadorFlight[0].origen === null
                    ? ''
                    : destinosCotizadorFlight[0].origen.name
                }}
              </h3>
            </div>
            <div class="d-flex justify-center align-center">
              <v-icon
                class="px-2"
                color="primary"
              >
                {{ cotizadorFlight.onlyIda ? icons.mdiArrowDown : icons.mdiSwapVertical }}
              </v-icon>
            </div>
            <div class="d-flex justify-center align-center">
              <h3>
                {{
                  destinosCotizadorFlight[0].destino === null
                    ? ''
                    : destinosCotizadorFlight[0].destino.name
                }}
              </h3>
            </div>
          </fragment>

          <div
            v-else
            class="d-flex justify-center align-center"
          >
            <h2>
              {{ $t('lbl.searchDestiny') }}
            </h2>
          </div>
          <div class="d-flex justify-center align-center mt-2">
            <p v-if="destinosCotizadorFlight[0].from !== null">
              {{ destinosCotizadorFlight[0].from | moment('DD MMMM, dddd') }}
            </p>
            <p
              v-if="
                !cotizadorFlight.onlyIda &&
                  !cotizadorFlight.destinosMultiples &&
                  destinosCotizadorFlight[0].to !== null
              "
              class="px-2"
            >
              -
            </p>
            <p
              v-if="
                !cotizadorFlight.onlyIda &&
                  !cotizadorFlight.destinosMultiples &&
                  destinosCotizadorFlight[0].to !== null
              "
            >
              {{ destinosCotizadorFlight[0].to | moment('DD MMMM, dddd') }}
            </p>
          </div>
          <div class="d-flex justify-center align-center">
            <p>
              {{ cotizadorFlight.cant_adults > 0 ? `${$t('lbl.adultos')} ${cotizadorFlight.cant_adults}` : '' }}
              <fragment v-if="cotizadorFlight.cant_ninnos > 0">
                ,
              </fragment>
              {{ cotizadorFlight.cant_ninnos > 0 ? `${$t('lbl.childs')} ${cotizadorFlight.cant_ninnos}` : '' }}
              <fragment v-if="cotizadorFlight.cant_infant > 0">
                ,
              </fragment>
              {{ cotizadorFlight.cant_infant > 0 ? `${$t('lbl.infants')} ${cotizadorFlight.cant_infant}` : '' }}
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col
        cols="12"
        md="3"
      ></v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-progress-linear
          class="mt-4"
          indeterminate
          color="primary"
        ></v-progress-linear>
      </v-col>
      <v-col
        cols="12"
        md="3"
      ></v-col>
    </v-row>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiArrowRight,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiArrowDown,
  mdiSwapVertical,
} from '@mdi/js'

export default {
  data() {
    return {
      iataOrigen: null,
      iataDestino: null,
      tab: 0,
      isLoadingFlihgt: true,
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      loading: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      aerolineas: [],
      radioGroup: 0,
      mostrarida: true,
      mostrarmultiple: false,
      mostraridavuelta: false,

      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiArrowRight,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiArrowDown,
        mdiSwapVertical,
      },
      isDialogVisible: false,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: sessionStorage.getItem('permisos_auth'),
      itemDelete: {},
      isDialogVisibleDelete: false,
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
    }),
  },
  created() {
    this.getOriden()
    this.getDestino()
  },
  methods: {
    ...mapMutations([
      'addDestinoCotizadorFligth',
      'resetDestinoCotizadorFligth',
      'incrementAdultosCotizadorFligth',
      'decrementAdultosCotizadorFligth',
      'incrementChildCotizadorFligth',
      'decrementChildCotizadorFligth',
      'incrementInfantCotizadorFligth',
      'decrementInfantCotizadorFligth',
    ]),
    getOriden() {
      this.axios
        .get(`airport/${this.destinosCotizadorFlight[0].origen.id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.iataOrigen = `${res.data.data.data.city} (${res.data.data.data.iata})`
        })
    },
    getDestino() {
      this.axios
        .get(`airport/${this.destinosCotizadorFlight[0].destino.id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.iataDestino = `${res.data.data.data.city} (${res.data.data.data.iata})`
        })
    },
  },
}
</script>

<style>
.theme--light.v-text-field.v-input--is-disabled .v-input__slot:before {
  border: none;
}

.v-input__slot {
  margin-bottom: 0px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div {
  padding: 0 !important;
}

#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(3)
  > div {
  padding: 0 !important;
  margin-top: 0 !important;
}

#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(4)
  > div {
  padding: 0 !important;
  margin-top: 0 !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div::before {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
  padding-bottom: 10px;
}
#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(2)
  > div.pl-0.col-md-3.col-12
  > div
  > div
  > div
  > div
  > label {
  padding-left: 10px !important;
  z-index: 999 !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div::before {
  box-shadow: none !important;
}
.boton-lefttMine,
.boton-rightMine {
  max-height: 20px !important;
  min-width: 40px !important;
  padding: 0px !important;
  border: none !important;
}

#weirdButMine {
  border: 1px solid #dcdade;
  max-height: 40px;
  border-radius: 5px;
}

#weirdButMine input {
  height: 20px;
  text-align: center;
}

#weirdButMine div:nth-child(2) > div {
  height: 20px;
  padding: 0;
  margin: 0;
}

#weirdButMine .v-input__slot:after {
  border: none !important;
}

#weirdButMine .v-input__slot:before {
  border: none;
}

#weirdButMine button::before {
  background-color: rgba(255, 255, 255, 0);
}

#weirdButMine button .v-ripple__container {
  color: rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0);
}

.vc-pane-layout {
  z-index: 99999;
}
</style>
