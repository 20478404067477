<template>
  <fragment v-if="!isLoading">
    <v-row
      no-gutters
      class="py-0"
    >
      <v-col
        cols="12"
        :md="!cotizadorCars.entregaDiffRecogida ? '6' : '3'"
        class="pr-0"
      >
        <div class="d-flex justify-start align-center">
          <v-autocomplete
            v-if="cotizadorCars.recogida === null || cotizadorCars.recogida === ''"
            v-model="cotizadorCars.recogida"
            :items="itemsRecogida"
            :search-input.sync="searchRecogida"
            :label="!cotizadorCars.entregaDiffRecogida ? $t('lbl.recogidaEntrega') : $t('lbl.recogida')"
            outlined
            dense
            :style="!cotizadorCars.entregaDiffRecogida ? '' : 'border-radius: 5px 0px 0px 5px'"
            item-text="nameComodin"
            item-value="nameComodin"
            single-line
            clearable
            return-object
            class="pr-2"
            @change="changeRecogida"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ noResultRecogida ? $t('lbl.noResultSearchFlight') : $t('lbl.recogida') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <v-icon left>
                {{ item.icon }}
              </v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.nameComodin }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:selection="{ item }">
              {{ item.name }}
            </template>
          </v-autocomplete>
          <v-text-field
            v-else
            ref="autocomplete"
            v-model="cotizadorCars.recogida.name"
            :label="!cotizadorCars.entregaDiffRecogida ? $t('lbl.recogidaEntrega') : $t('lbl.recogida')"
            outlined
            dense
            :style="!cotizadorCars.entregaDiffRecogida ? '' : 'border-radius: 5px 0px 0px 5px'"
            single-line
            clearable
            @click:clear="clearRecogida"
            @input="inputRecogida"
          >
          </v-text-field>
          <!--<v-btn
          color="primary"
          text
          fab
          x-small
          :disabled="!destinosCotizadorFlight[index].origen || !destinosCotizadorFlight[index].destino"
          @click="invertDestinos"
        >
          <v-icon>{{ icons.mdiSwapHorizontal }}</v-icon>
        </v-btn>-->
        </div>
      </v-col>
      <v-col
        v-if="cotizadorCars.entregaDiffRecogida"
        cols="12"
        md="3"
        class="pl-5"
      >
        <v-autocomplete
          v-if="cotizadorCars.entrega === null || cotizadorCars.entrega === ''"
          v-model="cotizadorCars.entrega"
          :items="itemsEntrega"
          :search-input.sync="searchEntrega"
          :label="$t('lbl.entrega')"
          outlined
          dense
          style="border-radius: 0px 5px 5px 0px"
          item-text="nameComodin"
          item-value="nameComodin"
          return-object
          single-line
          clearable
          class="pr-2"
          @change="changeEntrega"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ noResultEntrega ? $t('lbl.noResultSearchFlight') : $t('lbl.entrega') }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item }">
            <v-icon left>
              {{ item.icon }}
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.nameComodin }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:selection="{ item }">
            <span>{{ `${item.name.slice(0, 20)}...` }}</span>
          </template>
        </v-autocomplete>
        <v-text-field
          v-else
          ref="autocomplete"
          v-model="cotizadorCars.entrega.name"
          :label="$t('lbl.entrega')"
          outlined
          dense
          style="border-radius: 0px 5px 5px 0px"
          single-line
          clearable
          @click:clear="clearEntrega"
          @input="inputEntrega"
        >
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="pl-5"
      >
        <!--<v-menu
          v-model="menuFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedFromDateFormatted"
              :label="$t('lbl.dateRecogida')"
              class="d-flex justify-center;z-index: 1 !important"
              persistent-hint
              readonly
              v-bind="attrs"
              outlined
              dense
              style="border-radius: 5px 0px 0px 5px"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="cotizadorCars.dateRecogida"
            no-title
            :min="new Date(Date.now()).toISOString().substr(0, 10)"
            locale="es"
            :show-current="false"
            @input="
              menuFrom = false
              changeRecogida()
            "
          ></v-date-picker>
        </v-menu>-->
        <vc-date-picker
          v-model="cotizadorCars.dateRecogida"
          outlined
          dense
          hide-details
          :min-date="
            $moment(new Date())
              .add(minRelease, 'days')
              .toDate()
          "
          mode="dateTime"
          :model-config="modelConfig"
          class="d-flex justify-center;z-index: 1 !important"
          @input="changeRecogida"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <v-text-field
              :label="$t('lbl.dateRecogida')"
              outlined
              dense
              :value="inputValue"
              style="border-radius: 5px 0px 0px 5px"
              v-on="inputEvents"
            >
            </v-text-field>
          </template>
        </vc-date-picker>
        <span v-if="!no_include_hour_recogida">
          <span style="color: red">
            {{ $t('lbl.verifyHorarios') }}
          </span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error">
                  mdi-information
                </v-icon>
              </v-btn>
            </template>
            <span>
              <span class="text-subtitle-1">
                {{ `${$t('lbl.horariosDe')} ${cotizadorCars.recogida ? cotizadorCars.recogida.name : ''}` }} </span><br /><br />
              <span
                v-for="(hours_recog, indRec) in hours_recogida"
                :key="indRec"
              >
                <v-slide-group
                  v-model="hours_recog.modelSemana"
                  multiple
                  class="mb-2"
                >
                  <v-slide-item
                    v-for="(item, index) in semanas"
                    v-slot="{ active, toggle }"
                    :key="index"
                    class="px-1"
                  >
                    <v-btn
                      class=""
                      :input-value="active"
                      active-class="primary white--text"
                      depressed
                      rounded
                      x-small
                      @click="toggle"
                    >
                      {{ item }}
                    </v-btn>
                  </v-slide-item>
                </v-slide-group>
                <span
                  v-for="(horar, indH) in hours_recog.horarios"
                  :key="indH"
                  class="mt-2"
                >
                  <!--{{ horar.fromDate | moment('hh:mm a') }} - {{ horar.toDate | moment('hh:mm a') }}-->
                  {{ horar.from }} - {{ horar.to }}<br /> </span><br />
              </span>
            </span>
          </v-tooltip>
        </span>
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="pl-5"
      >
        <!--<v-menu
          v-model="menuTo"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedToDateFormatted"
              :disabled="!cotizadorCars.dateRecogida"
              :label="$t('lbl.dateEntrega')"
              persistent-hint
              readonly
              v-bind="attrs"
              outlined
              dense
              style="border-radius: 0px 5px 5px 0px"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="cotizadorCars.dateEntrega"
            no-title
            locale="es"
            :show-current="false"
            :min="
              cotizadorCars.dateRecogida
                ? $moment(cotizadorCars.dateRecogida)
                  .add(1, 'day')
                  .toDate()
                  .toISOString()
                  .substr(0, 10)
                : new Date(Date.now()).toISOString().substr(0, 10)
            "
            @input="
              menuTo = false
              changeEntrega()
            "
          ></v-date-picker>
        </v-menu>-->
        <vc-date-picker
          v-model="cotizadorCars.dateEntrega"
          outlined
          dense
          hide-details
          :min-date="
            $moment(cotizadorCars.dateRecogida)
              .add(1, 'days')
              .toDate()
          "
          mode="dateTime"
          :model-config="modelConfig"
          @input="changeEntrega"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <v-text-field
              ref="dateTo"
              :disabled="!cotizadorCars.dateRecogida"
              :label="$t('lbl.dateEntrega')"
              outlined
              dense
              :value="inputValue"
              style="border-radius: 0px 5px 5px 0px"
              v-on="inputEvents"
            >
            </v-text-field>
          </template>
        </vc-date-picker>
        <span v-if="!no_include_hour_entrega">
          <span style="color: red">
            {{ $t('lbl.verifyHorarios') }}
          </span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error">
                  mdi-information
                </v-icon>
              </v-btn>
            </template>
            <span>
              <span class="text-subtitle-1">
                {{
                  `${$t('lbl.horariosDe')}
                ${
                    cotizadorCars.entregaDiffRecogida
                      ? cotizadorCars.entrega
                        ? cotizadorCars.entrega.name
                        : ''
                      : cotizadorCars.recogida
                        ? cotizadorCars.recogida.name
                        : ''
                  }`
                }} </span><br /><br />
              <span
                v-for="(hours_entr, indEnt) in hours_entrega"
                :key="indEnt"
              >
                <v-slide-group
                  v-model="hours_entr.modelSemana"
                  multiple
                  class="mb-2"
                >
                  <v-slide-item
                    v-for="(item, index) in semanas"
                    v-slot="{ active, toggle }"
                    :key="index"
                    class="px-1"
                  >
                    <v-btn
                      class=""
                      :input-value="active"
                      active-class="primary white--text"
                      depressed
                      rounded
                      x-small
                      @click="toggle"
                    >
                      {{ item }}
                    </v-btn>
                  </v-slide-item>
                </v-slide-group>
                <span
                  v-for="(horar, indH) in hours_entr.horarios"
                  :key="indH"
                  class="mt-2"
                >
                  <!--{{ horar.fromDate | moment('hh:mm') }} - {{ horar.toDate | moment('hh:mm') }}-->
                  {{ horar.from }} - {{ horar.to }}<br /> </span><br />
              </span>
            </span>
          </v-tooltip>
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-switch
          v-model="cotizadorCars.entregaDiffRecogida"
          :label="$t('lbl.entregaDiffRecogida')"
          class="pb-0 mt-0"
          @change="reseatDiffRecogida"
        ></v-switch>
      </v-col>

      <v-col
        cols="12"
        md="2"
      >
        <v-select
          v-model="cotizadorCars.transmision_id"
          :items="transmisiones"
          item-text="name"
          item-value="id"
          :label="$t('menu.trasnmision')"
          outlined
          dense
          hide-details
          clearable
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-autocomplete
          v-model="cotizadorCars.rentadora_id"
          :items="itemsRentadora"
          :search-input.sync="searchRentadora"
          :label="$t('menu.rentadora')"
          outlined
          dense
          item-text="name"
          item-value="id"
          return-object
          single-line
          clearable
          class="pl-2"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ noResultRentadora ? $t('lbl.noResultSearchFlight') : $t('menu.rentadora') }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item }">
            <!--<v-icon left>
            {{ icons.mdiAirplaneTakeoff }}
          </v-icon>-->
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:selection="{ item }">
            <span>{{ `${item.name.slice(0, 20)}...` }}</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-row>
          <v-col
            cols="12"
            :md="
              !cotizadorCars.recogida &&
                !cotizadorCars.entrega &&
                !cotizadorCars.entregaDiffRecogida &&
                !cotizadorCars.dateRecogida &&
                !cotizadorCars.dateEntrega &&
                !cotizadorCars.transmision_id &&
                !cotizadorCars.rentadora_id
                ? '12'
                : '6'
            "
          >
            <v-btn
              color="primary"
              width="100%"
              :loading="loadingSearch"
              @click="search"
            >
              {{ $t('btn.search') }}
            </v-btn>
          </v-col>
          <v-col
            v-if="
              cotizadorCars.recogida ||
                cotizadorCars.entrega ||
                cotizadorCars.entregaDiffRecogida ||
                cotizadorCars.dateRecogida ||
                cotizadorCars.dateEntrega ||
                cotizadorCars.transmision_id ||
                cotizadorCars.rentadora_id
            "
            cols="12"
            md="6"
          >
            <v-btn
              color="error"
              width="100%"
              @click="clearForm"
            >
              {{ $t('btn.clear') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <DisponibilidadCars v-if="!loadingSearch && (cotizadorCarsResult.length > 0 || avalibleFiltrosCotizadorCar)" />
    <NoResultsCars v-else-if="searchingCar" />
    <div
      v-else
      style="min-height: 250px;"
    ></div>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
} from '@mdi/js'
import DisponibilidadCars from '../disponibilidad/cars/Index.vue'
import NoResultsCars from './NoResultsCars.vue'

export default {
  components: {
    DisponibilidadCars,
    NoResultsCars,
  },
  data() {
    return {
      isLoading: true,
      page: 1,
      per_page: 10,
      itemsRecogida: [],
      noResultRecogida: false,
      searchRecogida: null,
      itemsEntrega: [],
      noResultEntrega: false,
      searchEntrega: null,
      itemsPuntosRecogida: [],
      noResultPuntosRecogida: false,
      searchPuntosRecogida: null,
      itemsRentadora: [],
      noResultRentadora: false,
      searchRentadora: null,
      loading: false,
      loadingSearch: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm',
      },
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
      },
      model: {},
      transmisiones: [],
      no_include_hour_recogida: true,
      no_include_hour_entrega: true,
      hours_recogida: [],
      hours_entrega: [],
      semanas: [],
      menuFrom: false,
      menuTo: false,
      minRelease: 1,
    }
  },
  computed: {
    ...mapState({
      searchingCar: state => state.app.searchingCar,
      cotizadorCars: state => state.app.cotizadorCars,
      cotizadorCarsResult: state => state.app.cotizadorCarsResult,
      avalibleFiltrosCotizadorCar: state => state.app.avalibleFiltrosCotizadorCar,
      computedFromDateFormatted() {
        return this.cotizadorCars.dateRecogida ? this.$moment(this.cotizadorCars.dateRecogida).format('D-MMM-YY') : ''
      },
      computedToDateFormatted() {
        return this.cotizadorCars.dateEntrega ? this.$moment(this.cotizadorCars.dateEntrega).format('D-MMM-YY') : ''
      },
    }),
  },
  watch: {
    searchRecogida(val) {
      if (val) {
        if (val.length > 1) {
          // eslint-disable-next-line no-unused-expressions
          val && this.filterRecogida(val.toLowerCase())
        } else {
          this.noResultRecogida = false
        }
      }
    },
    searchEntrega(val) {
      if (val) {
        if (val.length > 1) {
          // eslint-disable-next-line no-unused-expressions
          val && this.filterEntrega(val.toLowerCase())
        } else {
          this.noResultEntrega = false
        }
      }
    },
    searchRentadora(val) {
      if (val.length > 1) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterRentadora(val.toLowerCase())
      } else {
        this.noResultRentadora = false
      }
    },
    dateFrom() {
      this.destinosCotizadorFlight[this.index].from = this.dateFrom
      if (!this.cotizadorFlight.onlyIda && !this.cotizadorFlight.destinosMultiples) {
        setTimeout(() => {
          this.$refs.dateTo.focus()
        }, 100)
      }
    },
  },
  mounted() {
    this.scroll()
  },
  created() {
    this.getTransmisiones()
    this.semanas.push('D')
    this.semanas.push('L')
    this.semanas.push('M')
    this.semanas.push('X')
    this.semanas.push('J')
    this.semanas.push('V')
    this.semanas.push('S')

    this.detectMinRelease()

    if (sessionStorage.getItem('clear-cotization') !== null) {
      this.clearForm()
      sessionStorage.removeItem('clear-cotization')
    }
  },
  methods: {
    ...mapMutations(['setCotizadorCarsResult', 'setSearchingCar', 'updateDriverAgeCumple']),
    filterRecogida(v) {
      this.itemsRecogida = []
      // eslint-disable-next-line no-empty
      if (v !== '') {
        // OFICINAS
        this.axios
          .post(
            'oficina-renta-filter-contrate',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              let icon = 'mdi-office-building-marker-outline'
              if (element.pto_recogida !== null) {
                if (element.punto_recogida.icon !== null) {
                  icon = element.punto_recogida.icon
                }
              }
              // eslint-disable-next-line no-nested-ternary
              this.itemsRecogida.push({
                id: element.id,
                name: element.name,
                nameComodin: element.nameComodin,
                type: 'oficina',
                icon,
              })
            })
          })
          .finally(() => {
            setTimeout(() => {
              if (this.itemsRecogida.length === 0) {
                this.noResultRecogida = true
              }
            }, 500)

            // DESTINOS
            /* this.axios
              .post(
                'destinos-filter-contrate',
                { search: v },
                {
                  headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
                  },
                },
              )
              .then(res => {
                const result = res.data.data.data
                result.forEach(element => {
                  // eslint-disable-next-line no-nested-ternary
                  this.itemsRecogida.push({
                    id: element.id,
                    name: element.destino,
                    type: 'destino',
                    icon: 'mdi-map-marker',
                  })
                })
              })
              .finally(() => {
                setTimeout(() => {
                  if (this.itemsRecogida.length === 0) {
                    this.noResultRecogida = true
                  }
                }, 500)
              }) */
          })

        // PUNTOS RECOGIDA
        /* this.axios
          .post(
            'punto-recogida-filter-contrate',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsRecogida.push({
                id: element.id,
                name: element.name,
                type: 'pto-recogida',
              })
            })
          }) */
      }
    },
    filterEntrega(v) {
      this.itemsEntrega = []

      if (v !== '') {
        // OFICINAS
        this.axios
          .post(
            'oficina-renta-filter-contrate',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              let icon = 'mdi-office-building-marker-outline'
              if (element.pto_recogida !== null) {
                if (element.punto_recogida.icon !== null) {
                  icon = element.punto_recogida.icon
                }
              }
              // eslint-disable-next-line no-nested-ternary
              this.itemsEntrega.push({
                id: element.id,
                name: element.name,
                nameComodin: element.nameComodin,
                type: 'oficina',
                icon,
              })
            })
          })
          .finally(() => {
            setTimeout(() => {
              if (this.itemsEntrega.length === 0) {
                this.noResultEntrega = true
              }
            }, 500)
          })

        // PUNTOS RECOGIDA
        /* this.axios
          .post(
            'punto-recogida-filter-contrate',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsEntrega.push({
                id: element.id,
                name: element.name,
                type: 'pto-recogida',
              })
            })
          }) */

        // DESTINOS
        /* this.axios
          .post(
            'destinos-filter-contrate',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsEntrega.push({
                id: element.id,
                name: element.destino,
                type: 'destino',
              })
            })
          }) */
      }
    },
    filterRentadora(v) {
      this.itemsRentadora = []
      if (v !== '') {
        this.axios
          .post(
            'rentadoras-filter-contrate',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsRentadora.push({
                id: element.id,
                name: element.name,
              })
            })

            if (this.itemsRentadora.length === 0) {
              this.noResultRentadora = true
            }
          })
      }
    },
    clearForm() {
      this.cotizadorCars.recogida = null
      this.cotizadorCars.entrega = null
      this.cotizadorCars.dateRecogida = null
      this.cotizadorCars.dateEntrega = null
      this.cotizadorCars.entregaDiffRecogida = false
      this.cotizadorCars.transmision_id = null
      this.cotizadorCars.rentadora_id = null

      this.setCotizadorCarsResult([])
      this.setSearchingCar(false)
      this.updateDriverAgeCumple(false)
    },
    getTransmisiones() {
      this.axios
        .get('nom_transmision_cars/list?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.transmisiones = res.data.data
        })
    },
    reseatDiffRecogida() {
      if (!this.cotizadorCars.entregaDiffRecogida) {
        this.cotizadorCars.entrega = null
        this.searchEntrega = null
        this.itemsEntrega = []
      }
    },

    detectMinRelease() {
      const json = {
        dateRecogida: this.$moment(new Date()).format('Y-M-D'),
      }

      // BUSCAR EL MENOR RELEASE
      this.axios
        .post('check-release-car-contrate', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.days) {
            this.minRelease = res.data.days
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    scroll() {
      window.onscroll = () => {
        const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
        if (bottomOfWindow) {
          const json = this.cotizadorCars

          let proceder = true
          if (this.cotizadorCars.entregaDiffRecogida) {
            if (!this.cotizadorCars.entrega) {
              proceder = false
            }
          }

          if (proceder) {
            this.page += 1
            // eslint-disable-next-line radix
            json.per_page = parseInt(this.page) * parseInt(this.per_page)
            this.axios
              .post('cotizador-search-car', json, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(res => {
                // console.log(res)
                this.setSearchingCar(true)

                if (res.data.success) {
                  this.setCotizadorCarsResult(res.data.data.items)
                }
              })
          }
        }
      }
    },
    search() {
      if (this.cotizadorCars.recogida && this.cotizadorCars.dateRecogida && this.cotizadorCars.dateEntrega) {
        this.loadingSearch = true
        let proceder = true

        if (this.cotizadorCars.entregaDiffRecogida) {
          if (!this.cotizadorCars.entrega) {
            proceder = false
          }
        }

        if (proceder) {
          this.setCotizadorCarsResult([])
          const json = this.cotizadorCars

          // console.log(json)

          // eslint-disable-next-line radix
          json.per_page = parseInt(this.page) * parseInt(this.per_page)

          this.axios
            .post('cotizador-search-car', json, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              // console.log(res)
              this.setSearchingCar(true)

              if (res.data.success) {
                this.setCotizadorCarsResult(res.data.data.items)
              }
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => {
              this.loadingSearch = false
            })
        } else {
          this.loadingSearch = false
          this.$toast.error(this.$t('msg.datasRequiere'))
        }
      } else {
        this.loadingSearch = false
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    clearRecogida() {
      this.cotizadorCars.recogida = null
      this.searchRecogida = null
    },
    inputRecogida() {
      if (this.cotizadorCars.recogida.name === null || this.cotizadorCars.recogida.name === '') {
        this.clearRecogida()
      }
    },
    clearEntrega() {
      this.cotizadorCars.entrega = null
      this.searchEntrega = null
    },
    inputEntrega() {
      if (this.cotizadorCars.entrega.name === null || this.cotizadorCars.entrega.name === '') {
        this.clearRecogida()
      }
    },

    changeRecogida() {
      if (this.cotizadorCars.recogida !== null) {
        if (this.cotizadorCars.recogida.type === 'oficina') {
          const idOffice = this.cotizadorCars.recogida
          if (this.cotizadorCars.dateRecogida) {
            const json = {
              idOffice: idOffice.id,
              dateRecogida: this.cotizadorCars.dateRecogida,
            }
            this.axios
              .post('check-hours-office-rent-car-contrate', json, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(res => {
                // console.log(res.data)
                const response = res.data
                this.no_include_hour_recogida = response.satisfaceRecogida
                this.hours_recogida = response.horariosOfficeRent

                /*
                response.horariosOfficeRent[0].horarios.forEach(element => {
                  this.hours_recogida.push(element)
                })

                this.model_hours_recogida = response.horariosOfficeRent[0].modelSemana */

                /* modelSemana.forEach(element => {
                  if (condition) {
                  }
                })
                console.log(this.model_hours_recogida) */

                // console.log(this.hours_recogida)
              })
          }
        }
      }

      /* if (this.cotizadorCars.dateRecogida !== null) {
        this.changeDateFrom()
      } */
    },
    changeEntrega() {
      let idOffice = null
      if (this.cotizadorCars.entregaDiffRecogida) {
        if (this.cotizadorCars.entrega !== null) {
          if (this.cotizadorCars.entrega.type === 'oficina') {
            idOffice = this.cotizadorCars.entrega
          }
        }
      } else if (this.cotizadorCars.recogida !== null) {
        if (this.cotizadorCars.recogida.type === 'oficina') {
          idOffice = this.cotizadorCars.recogida
        }
      }

      if (idOffice !== null) {
        if (this.cotizadorCars.dateEntrega !== null) {
          const json = {
            idOffice: idOffice.id,
            dateRecogida: this.cotizadorCars.dateEntrega,
          }
          this.axios
            .post('check-hours-office-rent-car-contrate', json, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              const response = res.data
              this.no_include_hour_entrega = response.satisfaceRecogida
              this.hours_entrega = response.horariosOfficeRent
            })
        }
      }
    },

    changeDateFrom() {
      const json = {
        dateRecogida: this.cotizadorCars.dateRecogida,
      }

      // VERIFICAR EL RELEASE
      this.axios
        .post('check-release-car-contrate', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.count > 0) {
          } else {
            this.$toast.error(this.$t('msg.noRelease', { days: res.data.days }))
          }
        })
    },
  },
}
</script>

<style>
/**PARA OCULTAR LA FECHA DE HOY EN EL COMPONENTE DE HORA */
.vc-date-time .vc-date .vc-weekday,
.vc-date-time .vc-date .vc-month,
.vc-date-time .vc-date .vc-day,
.vc-date-time .vc-date .vc-year {
  display: none;
}

.theme--light.v-text-field.v-input--is-disabled .v-input__slot:before {
  border: none;
}

.v-input__slot {
  margin-bottom: 0px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div {
  padding: 0 !important;
}

#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(3)
  > div {
  padding: 0 !important;
  margin-top: 0 !important;
}

#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(4)
  > div {
  padding: 0 !important;
  margin-top: 0 !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div::before {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
  padding-bottom: 10px;
}
#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(2)
  > div.pl-0.col-md-3.col-12
  > div
  > div
  > div
  > div
  > label {
  padding-left: 10px !important;
  z-index: 999 !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div::before {
  box-shadow: none !important;
}
.boton-lefttMine,
.boton-rightMine {
  max-height: 20px !important;
  min-width: 40px !important;
  padding: 0px !important;
  border: none !important;
}

#weirdButMine {
  border: 1px solid #dcdade;
  max-height: 40px;
  border-radius: 5px;
}

#weirdButMine input {
  height: 20px;
  text-align: center;
}

#weirdButMine div:nth-child(2) > div {
  height: 20px;
  padding: 0;
  margin: 0;
}

#weirdButMine .v-input__slot:after {
  border: none !important;
}

#weirdButMine .v-input__slot:before {
  border: none;
}

#weirdButMine button::before {
  background-color: rgba(255, 255, 255, 0);
}

#weirdButMine button .v-ripple__container {
  color: rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0);
}

/* PARA LA FECHA DE VCALENDAR*/
.vc-popover-content-wrapper {
  margin: 0px;
  position: fixed !important;
  inset: 0px auto auto 0px;
  transform: translate(150px, 80px);
}
</style>
