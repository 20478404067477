<template>
  <v-container
    v-if="!isLoading"
    fluid
    class="ma-2 rounded-lg"
    style="border: 1px solid"
  >
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-img
          :src="
            nameB2B === 'ttttt'
              ? rutaS3 + remplaceUrl(image)
              : nameB2B === 'wwwww'
                ? rutaS3 + image
                : rutaPublic + image
          "
          size="100"
          height="125"
        ></v-img>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <strong v-if="car.similares.length === 0">
              {{ car.car.name }}
              <v-chip
                class="ma-2"
                color="primary"
              >
                <span v-if="car.onRequest">On Request</span>
                <span v-else>Free Sale</span>
              </v-chip>
            </strong>
            <fragment v-else>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    style="cursor: pointer"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <strong>
                      {{ `${car.car.name} ${$t('lbl.orSimilar')}` }}
                      <v-chip
                        class="ma-2"
                        color="primary"
                      >
                        <span v-if="car.onRequest">On Request</span>
                        <span v-else>Free Sale</span>
                      </v-chip>
                    </strong>
                  </span>
                </template>
                <span>
                  <span
                    v-for="(simi, indSimi) in car.similares"
                    :key="indSimi"
                  > {{ simi.name }}<br /> </span>
                </span>
              </v-tooltip>
            </fragment>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <fragment>
              <v-icon color="primary">
                {{ icons.mdiCarDoor }}
              </v-icon>
              <b class="ml-2">{{ car.car.cant_puertas }}</b>
            </fragment><br />
            <fragment>
              <v-icon color="primary">
                {{ icons.mdiAccountOutline }}
              </v-icon>
              <b class="ml-2">{{ car.car.cant_paxs }}</b>
            </fragment><br />
            <fragment>
              <v-icon color="primary">
                {{ icons.mdiBagSuitcaseOutline }}
              </v-icon>
              <b class="ml-2">{{ car.car.cant_maletas_small }}</b>
            </fragment>
          </v-col>
          <v-col
            cols="12"
            md="9"
          >
            <fragment v-if="car.rentadora.icon === null">
              {{ car.rentadora.name }}
            </fragment>
            <fragment v-else>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-img
                    :src="rutaPublic + car.rentadora.icon"
                    size="20"
                    height="30"
                    contain
                    aspect-ratio="1"
                  ></v-img>
                </v-col>
                <!--<v-col
                  cols="12"
                  md="12"
                >
                  <b>{{ car.car.transmision_id !== null ? `${$t('menu.trasnmision')} ${car.car.transmision.name}` : '' }}</b>
                </v-col>-->
                <v-col
                  cols="12"
                  md="12"
                  class="mt-3"
                >
                  <fragment
                    v-for="(cara, indCara) in car.car.caracteristicas"
                    :key="indCara"
                    top
                  >
                    <v-tooltip
                      v-if="cara"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ cara.icon }}
                        </v-icon>
                      </template>
                      <span>{{ cara.name }}</span>
                    </v-tooltip>
                  </fragment>
                </v-col>
              </v-row>
            </fragment>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="text-center"
      >
        <span><b>{{ $t('lbl.totalDesde') }}</b></span>
        <br />
        <h1>
          <span :style="`color: ${$vuetify.theme.themes.light.primary}`">{{ car.tarifaAllDayMark | currency }}</span>
        </h1>
        <span><b>{{ $t('lbl.byDayDesde') }}</b></span>
        <br />
        <span :style="`color: ${$vuetify.theme.themes.light.primary}`">{{ car.tarifaDayMark | currency }}</span>
        <br />
        <v-btn
          color="primary"
          class="mb-4 me-3"
          :loading="loadingResult"
          @click="send(car)"
        >
          <span>{{ $t('btn.select') }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiCar, mdiBed, mdiCarDoor, mdiAccountOutline, mdiBagSuitcaseOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    indCar: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    car: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      loadingResult: false,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_CAR,
      isLoadingTarifaEcon: false,
      radioGroup: 0,
      image: [],
      icons: {
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorCarsResult: state => state.app.cotizadorCarsResult,
    }),
  },
  created() {
    this.loadCar()
  },
  methods: {
    ...mapMutations([
      'setTarifaEconCotizadorIdaFligth',
      'setIndexTarifaEconCotizadorIdaFligth',
      'setCotizadorCarsResultSelect',
    ]),
    remplaceUrl(url) {
      let img = url.replace('uploads/car/', '')
      img = img.replace(' ', '%20')

      return img
    },
    loadCar() {
      if (this.car.car.galery === null) {
        this.image = 'config/car_placeholder.png'
      } else {
        this.image = this.car.car.galery_random
      }

      setTimeout(() => {
        this.isLoading = false
      }, 100)
    },
    send(car) {
      this.loadingResult = true
      this.setCotizadorCarsResultSelect(car)
      this.$router.push({ name: 'selected-cotizador-cars' })
    },
  },
}
</script>

<style scoped>
/* Disponibilidad vuelo */
#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 30%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 40% !important;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px;
}

.p12Mine {
  font-size: 12px;
}
</style>
