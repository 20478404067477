<template>
  <fragment>
    <v-card>
      <v-card-text>
        <RoomOcupation
          v-for="(room, indHab) in cotizadorHotels.ocupation"
          :key="indHab"
          :i="indHab + 1"
          :pos="indHab"
          :room="room"
          :min_adult="configSearch.min_adult_room"
          :max_adult="configSearch.max_adult_room"
          :max_ocupation="configSearch.max_paxs_room"
          :max_travels="configSearch.max_paxs_all"
          :edad_infants="configSearch.age_infant"
          :edad_child="configSearch.age_child"
          :min_child="configSearch.min_child_room"
          :max_child="configSearch.max_child_room"
        />
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-btn
              align="left"
              block
              class="font-weight-medium"
              outlined
              color="#8a8d93"
              @click="setShowRoomHotelCotizador(!showOcupationRoomHotel)"
            >
              {{ $t('btn.apply') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import RoomOcupation from './RoomOcupation.vue'

export default {
  components: {
    RoomOcupation,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    configSearch: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      cotizadorHotels: state => state.app.cotizadorHotels,
      showOcupationRoomHotel: state => state.app.showOcupationRoomHotel,
    }),
  },
  methods: {
    ...mapMutations(['setShowRoomHotelCotizador']),
  },
}
</script>
