var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"d-block justify-center align-center",attrs:{"id":"cajaIntMine"}},[(!_vm.cotizadorFlight.destinosMultiples)?_c('fragment',[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('h3',[_vm._v(" "+_vm._s(_vm.destinosCotizadorFlight[0].origen === null ? '' : _vm.destinosCotizadorFlight[0].origen.name)+" ")])]),_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-icon',{staticClass:"px-2",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.cotizadorFlight.onlyIda ? _vm.icons.mdiArrowDown : _vm.icons.mdiSwapVertical)+" ")])],1),_c('div',{staticClass:"d-flex justify-center align-center"},[_c('h3',[_vm._v(" "+_vm._s(_vm.destinosCotizadorFlight[0].destino === null ? '' : _vm.destinosCotizadorFlight[0].destino.name)+" ")])])]):_c('div',{staticClass:"d-flex justify-center align-center"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('lbl.searchDestiny'))+" ")])]),_c('div',{staticClass:"d-flex justify-center align-center mt-2"},[(_vm.destinosCotizadorFlight[0].from !== null)?_c('p',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.destinosCotizadorFlight[0].from,'DD MMMM, dddd'))+" ")]):_vm._e(),(
              !_vm.cotizadorFlight.onlyIda &&
                !_vm.cotizadorFlight.destinosMultiples &&
                _vm.destinosCotizadorFlight[0].to !== null
            )?_c('p',{staticClass:"px-2"},[_vm._v(" - ")]):_vm._e(),(
              !_vm.cotizadorFlight.onlyIda &&
                !_vm.cotizadorFlight.destinosMultiples &&
                _vm.destinosCotizadorFlight[0].to !== null
            )?_c('p',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.destinosCotizadorFlight[0].to,'DD MMMM, dddd'))+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex justify-center align-center"},[_c('p',[_vm._v(" "+_vm._s(_vm.cotizadorFlight.cant_adults > 0 ? ((_vm.$t('lbl.adultos')) + " " + (_vm.cotizadorFlight.cant_adults)) : '')+" "),(_vm.cotizadorFlight.cant_ninnos > 0)?_c('fragment',[_vm._v(" , ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.cotizadorFlight.cant_ninnos > 0 ? ((_vm.$t('lbl.childs')) + " " + (_vm.cotizadorFlight.cant_ninnos)) : '')+" "),(_vm.cotizadorFlight.cant_infant > 0)?_c('fragment',[_vm._v(" , ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.cotizadorFlight.cant_infant > 0 ? ((_vm.$t('lbl.infants')) + " " + (_vm.cotizadorFlight.cant_infant)) : '')+" ")],1)])],1)])],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12","md":"3"}}),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-progress-linear',{staticClass:"mt-4",attrs:{"indeterminate":"","color":"primary"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }