var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('v-container',{staticClass:"ma-2 rounded-lg",staticStyle:{"border":"1px solid"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-img',{attrs:{"contain":"","src":_vm.resource === 's3' ? _vm.rutaS3 + _vm.image : _vm.rutaPublic + _vm.image,"size":"100","height":"200"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.hotel.hotel.name)+" ")]),_c('br'),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":_vm.hotel.hotel.categoria.plus ? '4' : '12'}},[_c('v-rating',{attrs:{"background-color":"default","color":"primary","empty-icon":_vm.icons.mdiStarOutline,"full-icon":_vm.icons.mdiStar,"half-icon":_vm.icons.mdiStarHalfFull,"length":"5","readonly":"","size":"20","dense":"","half-increments":"","value":_vm.hotel.hotel.categoria.cant_estrellas}})],1),(_vm.hotel.hotel.categoria.plus)?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_vm._v(" PLUS ")]):_vm._e()],1),_c('br'),_c('span',[_vm._v(_vm._s(_vm.hotel.hotel.operador ? ((_vm.$t('lbl.cadena')) + ": " + (_vm.hotel.hotel.operador.name)) : ''))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.hotel.proveedor ? ((_vm.$t('lbl.proveedor')) + ": " + (_vm.hotel.proveedor.name)) : ''))]),_c('br'),_c('i',{staticClass:"text-caption"},[_vm._v(_vm._s(((_vm.hotel.hotel.state) + ", " + (_vm.hotel.hotel.country))))]),_c('br'),_c('i',{staticClass:"text-caption"},[_vm._v(_vm._s(("" + (_vm.hotel.hotel.tipo_destino.name))))]),_c('br'),_c('v-row',{attrs:{"no-gutters":""}},[(_vm.hotel.hotel.cant_departament.cant_rooms)?_c('v-col',{attrs:{"cols":"12","md":"2 "}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-bed-outline ")]),_c('span',[_vm._v(" "+_vm._s(_vm.hotel.hotel.cant_departament.cant_rooms)+" ")])]}}],null,false,4152313911)},[_c('span',[_vm._v(_vm._s(_vm.$t('lbl.cantRoom')))])])],1):_vm._e(),(_vm.hotel.hotel.cant_departament.cant_restaurant)?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-silverware-fork-knife ")]),_c('span',[_vm._v(" "+_vm._s(_vm.hotel.hotel.cant_departament.cant_restaurant)+" ")])]}}],null,false,2514399217)},[_c('span',[_vm._v(_vm._s(_vm.$t('lbl.cantRest')))])])],1):_vm._e(),(_vm.hotel.hotel.cant_departament.cant_bars)?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-glass-cocktail ")]),_c('span',[_vm._v(" "+_vm._s(_vm.hotel.hotel.cant_departament.cant_bars)+" ")])]}}],null,false,3531606116)},[_c('span',[_vm._v(_vm._s(_vm.$t('lbl.cantBars')))])])],1):_vm._e(),(_vm.hotel.hotel.cant_departament.cant_pool)?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pool ")]),_c('span',[_vm._v(" "+_vm._s(_vm.hotel.hotel.cant_departament.cant_pool)+" ")])]}}],null,false,4237479733)},[_c('span',[_vm._v(_vm._s(_vm.$t('lbl.cantPiscinas')))])])],1):_vm._e(),(_vm.hotel.hotel.cant_departament.cant_coffee)?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-coffee ")]),_c('span',[_vm._v(" "+_vm._s(_vm.hotel.hotel.cant_departament.cant_coffee)+" ")])]}}],null,false,443454517)},[_c('span',[_vm._v(_vm._s(_vm.$t('lbl.cantCafeterias')))])])],1):_vm._e()],1)],1)],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"3"}},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.$tc('lbl.nightsFrom', _vm.hotel.days_search)))])]),_c('br'),_c('h1',[_c('span',{style:(("color: " + (_vm.$vuetify.theme.themes.light.primary)))},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.hotel.minPriceMark))+" ")])]),_c('br'),_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary","loading":_vm.loadingResult},on:{"click":function($event){return _vm.send(_vm.hotel)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.select')))])])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }