<template>
  <v-container
    v-if="!isLoading"
    fluid
    class="ma-2 rounded-lg"
    style="border: 1px solid"
  >
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-img
          contain
          :src="resource === 's3' ? rutaS3 + image : rutaPublic + image"
          size="100"
          height="200"
        ></v-img>
      </v-col>
      <v-col
        cols="12"
        md="5"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <strong> {{ hotel.hotel.name }} </strong><br />
            <v-row no-gutters>
              <v-col
                cols="12"
                :md="hotel.hotel.categoria.plus ? '4' : '12'"
              >
                <v-rating
                  background-color="default"
                  color="primary"
                  :empty-icon="icons.mdiStarOutline"
                  :full-icon="icons.mdiStar"
                  :half-icon="icons.mdiStarHalfFull"
                  length="5"
                  readonly
                  size="20"
                  dense
                  half-increments
                  :value="hotel.hotel.categoria.cant_estrellas"
                ></v-rating>
              </v-col>
              <v-col
                v-if="hotel.hotel.categoria.plus"
                cols="12"
                md="2"
              >
                PLUS
              </v-col>
            </v-row><br />
            <span>{{ hotel.hotel.operador ? `${$t('lbl.cadena')}: ${hotel.hotel.operador.name}` : '' }}</span><br />
            <span>{{ hotel.proveedor ? `${$t('lbl.proveedor')}: ${hotel.proveedor.name}` : '' }}</span><br />
            <i class="text-caption">{{ `${hotel.hotel.state}, ${hotel.hotel.country}` }}</i><br />
            <i class="text-caption">{{ `${hotel.hotel.tipo_destino.name}` }}</i><br />
            <!--<v-tooltip
              v-for="(serv, indServ) in hotel.hotel.servicios_id"
              :key="indServ"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  class="mr-1"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  {{
                    services.filter(e => e.id === serv).length > 0 ? services.filter(e => e.id === serv)[0].icon : ''
                  }}
                </v-icon>
              </template>
              <span>{{
                services.filter(e => e.id === serv).length > 0 ? services.filter(e => e.id === serv)[0].name : ''
              }}</span>
            </v-tooltip>-->
            <v-row no-gutters>
              <v-col
                v-if="hotel.hotel.cant_departament.cant_rooms"
                cols="12"
                md="2 "
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      mdi-bed-outline
                    </v-icon>
                    <span>
                      {{ hotel.hotel.cant_departament.cant_rooms }}
                    </span>
                  </template>
                  <span>{{ $t('lbl.cantRoom') }}</span>
                </v-tooltip>
              </v-col>
              <v-col
                v-if="hotel.hotel.cant_departament.cant_restaurant"
                cols="12"
                md="2"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      mdi-silverware-fork-knife
                    </v-icon>
                    <span>
                      {{ hotel.hotel.cant_departament.cant_restaurant }}
                    </span>
                  </template>
                  <span>{{ $t('lbl.cantRest') }}</span>
                </v-tooltip>
              </v-col>

              <v-col
                v-if="hotel.hotel.cant_departament.cant_bars"
                cols="12"
                md="2"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      mdi-glass-cocktail
                    </v-icon>
                    <span>
                      {{ hotel.hotel.cant_departament.cant_bars }}
                    </span>
                  </template>
                  <span>{{ $t('lbl.cantBars') }}</span>
                </v-tooltip>
              </v-col>
              <v-col
                v-if="hotel.hotel.cant_departament.cant_pool"
                cols="12"
                md="2"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      mdi-pool
                    </v-icon>
                    <span>
                      {{ hotel.hotel.cant_departament.cant_pool }}
                    </span>
                  </template>
                  <span>{{ $t('lbl.cantPiscinas') }}</span>
                </v-tooltip>
              </v-col>
              <v-col
                v-if="hotel.hotel.cant_departament.cant_coffee"
                cols="12"
                md="2"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      mdi-coffee
                    </v-icon>
                    <span>
                      {{ hotel.hotel.cant_departament.cant_coffee }}
                    </span>
                  </template>
                  <span>{{ $t('lbl.cantCafeterias') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!--<v-row>
          <v-col
            cols="12"
            md="3"
          >
            <fragment>
              <v-icon color="primary">
                {{ icons.mdiCarDoor }}
              </v-icon>
              <b class="ml-5">{{ hotel.hotel.cant_puertas }}</b>
            </fragment><br />
            <fragment>
              <v-icon color="primary">
                {{ icons.mdiAccountOutline }}
              </v-icon>
              <b class="ml-5">{{ hotel.hotel.cant_paxs }}</b>
            </fragment><br />
            <fragment>
              <v-icon color="primary">
                {{ icons.mdiBagSuitcaseOutline }}
              </v-icon>
              <b class="ml-5">{{ hotel.hotel.cant_maletas_small }}</b>
            </fragment>
          </v-col>
          <v-col
            cols="12"
            md="9"
          >
            <fragment v-if="hotel.rentadora.icon === null">
              {{ hotel.rentadora.name }}
            </fragment>
            <fragment v-else>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-img
                    :src="rutaPublic + hotel.rentadora.icon"
                    size="20"
                    height="30"
                    contain
                    aspect-ratio="1"
                  ></v-img>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="mt-3"
                >
                  <v-tooltip
                    v-for="(cara, indCara) in hotel.hotel.caracteristicas"
                    :key="indCara"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        class="mr-1"
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ cara.icon }}
                      </v-icon>
                    </template>
                    <span>{{ cara.name }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </fragment>
          </v-col>
        </v-row>-->
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="text-center"
      >
        <span><b>{{ $tc('lbl.nightsFrom', hotel.days_search) }}</b></span>
        <br />
        <h1>
          <span :style="`color: ${$vuetify.theme.themes.light.primary}`">
            {{ hotel.minPriceMark | currency }}
          </span>
        </h1>
        <!--<span><b>{{ $t('lbl.byDayDesde') }}</b></span>
        <br />
        <span :style="`color: ${$vuetify.theme.themes.light.primary}`">{{ hotel.tarifaDay | currency }}</span>-->
        <br />
        <v-btn
          color="primary"
          class="mb-4 me-3"
          :loading="loadingResult"
          @click="send(hotel)"
        >
          <span>{{ $t('btn.select') }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiCar,
  mdiBed,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
  mdiStarOutline,
  mdiStar,
  mdiStarHalfFull,
  mdiBedEmpty,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    indHotel: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      loadingResult: false,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      rutaS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      isLoadingTarifaEcon: false,
      radioGroup: 0,
      image: [],
      resource: 'local',
      services: [],
      icons: {
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
        mdiStarOutline,
        mdiStar,
        mdiStarHalfFull,
        mdiBedEmpty,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorHotels: state => state.app.cotizadorHotels,
      cotizadorHotelsResult: state => state.app.cotizadorHotelsResult,
      cotizadorHotelsResultSelect: state => state.app.cotizadorHotelsResultSelect,
    }),
  },
  created() {
    this.loadHotel()
    this.getServices()
  },
  methods: {
    ...mapMutations([
      'setTarifaEconCotizadorIdaFligth',
      'setIndexTarifaEconCotizadorIdaFligth',
      'setCotizadorHotelsResultSelect',
      'updateOcupationHotel',
      'setCotizadorHotelsRomms',
      'setCotizadorHotelsSelectMarkups',
    ]),
    loadHotel() {
      if (this.hotel.hotel.galery === null) {
        this.image = 'config/hotel_placeholder.png'
      } else if (this.hotel.hotel.galery_random === null) {
        this.image = 'config/hotel_placeholder.png'
      } else {
        this.image = this.hotel.hotel.galery_random
        this.resource = this.hotel.hotel.resource_random
      }

      setTimeout(() => {
        this.isLoading = false
      }, 100)
    },
    getServices() {
      this.axios
        .get('nom_servicios_hotels?per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.services = res.data.data
          }
        })
    },
    send(hotel) {
      this.loadingResult = true
      this.setCotizadorHotelsResultSelect(hotel)

      // console.log(hotel)

      // SUGERIR LAS HABITACIONES
      // eslint-disable-next-line no-plusplus
      for (let pos = 0; pos < this.cotizadorHotels.ocupation.length; pos++) {
        // eslint-disable-next-line no-unused-vars
        const ocupation = this.cotizadorHotels.ocupation[pos]

        let priceForRoom = 0
        let uso = ''
        const hab = this.cotizadorHotelsResultSelect.rooms[0]
        const { plan } = hab

        let mark = {
          markup: 0,
          valueMarkup: 0,
          descuento: 0,
          valueDescuento: 0,
        }

        hab.days.forEach(day => {
          day.pricesSeasonDay.forEach(pricesSeasonD => {
            if (pricesSeasonD.pax === 'sgl' && ocupation.adults === 1) {
              priceForRoom += pricesSeasonD.price
              uso = 'sgl'
              if (pricesSeasonD.markups) {
                mark = pricesSeasonD.markups
              }
              this.setCotizadorHotelsSelectMarkups(mark)
            } else if (pricesSeasonD.pax === 'dbl' && ocupation.adults === 2) {
              priceForRoom += pricesSeasonD.price * ocupation.adults
              uso = 'dbl'

              if (pricesSeasonD.markups) {
                mark = pricesSeasonD.markups
              }
              this.setCotizadorHotelsSelectMarkups(mark)
            } else if (pricesSeasonD.pax === 'tpl' && ocupation.adults === 3) {
              priceForRoom += pricesSeasonD.price * ocupation.adults
              uso = 'tpl'
              if (pricesSeasonD.markups) {
                mark = pricesSeasonD.markups
              }
              this.setCotizadorHotelsSelectMarkups(mark)
            } else if (pricesSeasonD.pax === 'pax4' && ocupation.adults === 4) {
              priceForRoom += pricesSeasonD.price * ocupation.adults
              uso = 'pax4'
              if (pricesSeasonD.markups) {
                mark = pricesSeasonD.markups
              }
              this.setCotizadorHotelsSelectMarkups(mark)
            } else if (pricesSeasonD.pax === 'pax5' && ocupation.adults === 5) {
              priceForRoom += pricesSeasonD.price * ocupation.adults
              uso = 'pax5'
              if (pricesSeasonD.markups) {
                mark = pricesSeasonD.markups
              }
              this.setCotizadorHotelsSelectMarkups(mark)
            } else if (pricesSeasonD.pax === 'pax6' && ocupation.adults === 6) {
              priceForRoom += pricesSeasonD.price * ocupation.adults
              uso = 'pax6'
              if (pricesSeasonD.markups) {
                mark = pricesSeasonD.markups
              }
              this.setCotizadorHotelsSelectMarkups(mark)
            } else if (pricesSeasonD.pax === 'pax7' && ocupation.adults === 7) {
              priceForRoom += pricesSeasonD.price * ocupation.adults
              uso = 'pax7'
              if (pricesSeasonD.markups) {
                mark = pricesSeasonD.markups
              }
              this.setCotizadorHotelsSelectMarkups(mark)
            } else if (pricesSeasonD.pax === 'pax8' && ocupation.adults === 8) {
              priceForRoom += pricesSeasonD.price * ocupation.adults
              uso = 'pax8'
              if (pricesSeasonD.markups) {
                mark = pricesSeasonD.markups
              }
              this.setCotizadorHotelsSelectMarkups(mark)
            }
          })
        })

        const paxs = []
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.cotizadorHotels.ocupation[pos].adults; i++) {
          let principal = false
          if (i === 0) {
            principal = true
          }
          paxs.push({
            principal,
            name: null,
            apellidos: null,
            noDocument: null,
          })
        }
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.cotizadorHotels.ocupation[pos].childs; i++) {
          paxs.push({
            principal: false,
            name: null,
            apellidos: null,
            noDocument: null,
          })
        }

        this.updateOcupationHotel({
          pos,
          paxs,
          room_id: hab.room_id,
          plan,
          uso,
          priceTotal: priceForRoom,
          priceWithMarkups: priceForRoom,
          priceWithDescuento: priceForRoom,
        })
        this.setCotizadorHotelsRomms()
      }

      this.$router.push({ name: 'selected-cotizador-hotels' })
    },
  },
}
</script>

<style scoped>
/* Disponibilidad vuelo */
#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 30%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 40% !important;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px;
}

.p12Mine {
  font-size: 12px;
}
</style>
